import queryString from "query-string";

export const apiURL = process.env.REACT_APP_API_URL || window.location.origin + "/api";

/**
 * Defines the API response format.
 *
 * @template T - The type of the `data` field.
 * @template U - Additional response attributes (can be any type).
 */
export type Res<T, U = unknown> = { data: T } & (U extends never ? {} : U);

/**
 * Defines the payload structure for making API requests.
 */
export type ApiClientPayload = {
	data?: any;
	params?: Record<string, any>;
	token?: string | null;
	headers?: Record<string, string>;
	formData?: FormData;
	signal?: AbortSignal;
	method?: "GET" | "POST" | "PUT" | "DELETE";
	getRawResponse?: boolean;
};

/**
 * Function overload: If `getRawResponse` is `true`, return a `Response` object.
 */
export async function apiClient(
	endpoint: string,
	options: ApiClientPayload & { getRawResponse: true },
): Promise<Response>;

/**
 * Function overload: If `getRawResponse` is `false` or undefined, return a structured `Res<T, U>`.
 */
export async function apiClient<T, U = unknown>(
	endpoint: string,
	options?: ApiClientPayload,
): Promise<Res<T, U> | undefined>;

/**
 * A generic API client for making HTTP requests.
 *
 * @template T - The type of the `data` field in the response.
 * @template U - The type of any additional response attributes (can be anything).
 *
 * @param {string} endpoint - The API endpoint to call.
 * @param {ApiClientPayload} [options] - The request options.
 * @returns {Promise<Res<T, U> | Response | undefined>} - The API response.
 */
export async function apiClient<T, U = unknown>(
	endpoint: string,
	{ data, token, method, headers: customHeaders, formData, signal, getRawResponse, params }: ApiClientPayload = {},
): Promise<Res<T, U> | Response | undefined> {
	const config: RequestInit = {
		method: method ?? (data || formData ? "POST" : "GET"),
		body: data ? JSON.stringify(data) : formData ?? undefined,
		signal,
		headers: {
			...(token && { Authorization: `Bearer ${token}` }),
			...(data && { "Content-Type": "application/json" }),
			...customHeaders,
		},
	};

	if (params) {
		const sanitizedParams = { ...params };
		Object.keys(sanitizedParams).forEach((key) => {
			if (sanitizedParams[key] == null) delete sanitizedParams[key];
		});

		const queryStringParams = queryString.stringify(sanitizedParams, { arrayFormat: "bracket" });
		if (queryStringParams) {
			endpoint += "?" + queryStringParams;
		}
	}

	const response = await fetch(`${apiURL}/${endpoint}`, config);

	// Handle authentication errors
	if (response.status === 401) {
		window.localStorage.clear();
		if (window.location.pathname.includes("admin/room")) {
			window.close();
		}
		window.location.reload();
		return Promise.reject({ message: "Please re-authenticate." });
	}

	// No content response
	if (response.status === 204) {
		return;
	}

	// If raw response is requested, return Response object
	if (getRawResponse) {
		return response;
	}

	if (formData) {
		if (response.ok) {
			return response;
		} else {
			const json = await response.json();
			console.log("REJECT", json);
			return Promise.reject({ status: response.status, response, json });
		}
	}

	// Process JSON response
	const json = await response.json();
	if (response.ok) {
		return json;
	} else {
		console.log("REJECT", json);
		return Promise.reject({ json, status: response.status, response });
	}
}
